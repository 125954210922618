import React from "react";

import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { FaDownload, FaNodeJs, FaReact } from "react-icons/fa";
import {
    SiBootstrap,
    SiCss3,
    SiExpress,
    SiFigma,
    SiFirebase,
    SiGithub,
    SiHtml5,
    SiJavascript,
    SiMongodb,
    SiRedux,
    SiTailwindcss,
    SiTypescript,
    SiVsco,
} from "react-icons/si";

const About = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
    });
    return (
        <div className="section" id="about">
            <div className="container mx-auto">
                <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen mb-[500px] lg:mb-0">
                    {/* img */}
                    {/* add skills */}
                    <motion.div
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1 mix-blend-lighten  grid grid-cols-2 gap-x-6 gap-y-6 bg-top"
                    >
                        <div className="">
                            <div className=" py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <FaReact className="text-white text-2xl" />
                                ReactJS
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <FaNodeJs className="text-white text-2xl" />
                                NodeJs
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiExpress className="text-white text-2xl" />
                                ExpressJs
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiMongodb className="text-white text-2xl" />
                                MongoDB
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiFirebase className="text-white text-2xl" />
                                Firebase
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiJavascript className="text-white text-2xl" />
                                JavaScript
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiTypescript className="text-white text-2xl" />
                                TypeScript
                            </div>
                        </div>
                        <div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiHtml5 className="text-white text-2xl" />
                                HTML 5
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiCss3 className="text-white text-2xl" />
                                CSS 3
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiTailwindcss className="text-white text-2xl" />
                                Tailwind CSS
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiBootstrap className="text-white text-2xl" />
                                Bootstrap
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiGithub className="text-white text-2xl" />
                                Git
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiRedux className="text-white text-2xl" />
                                Redux
                            </div>
                            <div className="py-2 mb-2 rounded-xl text-white flex flex-col items-center bg-gradient-to-r from-blue-500 to-purple-500 via-pink-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-500 cursor-pointer hover:scale-105">
                                <SiFigma className="text-white text-2xl" />
                                Figma
                            </div>
                        </div>
                    </motion.div>
                    {/* <motion.div
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top"
                    ></motion.div> */}
                    {/* txt */}
                    <InView threshold={0.5}>
                        {({ inView, ref }) => (
                            <motion.div
                                variants={fadeIn("left", 0.3)}
                                initial="hidden"
                                whileInView={"show"}
                                viewport={{ once: false, amount: 0.3 }}
                                className="flex-1"
                                ref={ref}
                            >
                                <h1 className="h2 text-accent">About Me</h1>
                                <h3 className="h3 mb-4">
                                    I'm a MERN Stack developer
                                </h3>
                                <p className="mb-6">
                                    I create seamless digital experiences that
                                    captivate users and drive results. Let's
                                    collaborate and build something
                                    extraordinary together!
                                </p>
                                {/* stats */}
                                <div className="flex gap-x-6 lg:gap-x-10 mb-12">
                                    <div>
                                        <div className="text-[40px] font-tertiary text-gradient mb-2">
                                            {inView ? (
                                                <CountUp
                                                    start={0}
                                                    end={12}
                                                    duration={3}
                                                />
                                            ) : null}
                                            +
                                        </div>
                                        <div className="font-primary text-sm tracking-[2px]">
                                            Months of <br /> Expierence
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-[40px] font-tertiary text-gradient mb-2">
                                            {inView ? (
                                                <CountUp
                                                    start={0}
                                                    end={20}
                                                    duration={3}
                                                />
                                            ) : null}
                                            +
                                        </div>
                                        <div className="font-primary text-sm tracking-[2px]">
                                            Number of <br /> Projects
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-[40px] font-tertiary text-gradient mb-2">
                                            {inView ? (
                                                <CountUp
                                                    start={0}
                                                    end={14}
                                                    duration={3}
                                                />
                                            ) : null}
                                            +
                                        </div>
                                        <div className="font-primary text-sm tracking-[2px]">
                                            Number of <br /> Technologies
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-x-8 items-center ">
                                    <button className="btn btn-lg">
                                        <a
                                            href="https://drive.google.com/uc?id=1QdYwDZMGuXZPZvaBPRrrTyI7ZI_gR8hP&export=download"
                                            className="flex items-center gap-x-2"
                                        >
                                            <FaDownload></FaDownload>
                                            Download Resume
                                        </a>
                                    </button>
                                    <a
                                        href="#contact"
                                        className="text-gradient btn-link"
                                    >
                                        Contact
                                    </a>
                                </div>
                            </motion.div>
                        )}
                    </InView>
                </div>
            </div>
        </div>
    );
};

export default About;

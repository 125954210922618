import React from "react";

import { BiHomeAlt, BiUser } from "react-icons/bi";
import { BsBriefcase, BsChatSquareText, BsClipboardData } from "react-icons/bs";
import { Link } from "react-scroll";

const Nav = () => {
    return (
        <nav className="fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50">
            <div className="container mx-auto">
                {/* nav inner */}
                <div className="w-full bg-black/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[450px] mx-auto px-5 flex justify-around items-center text-2xl text-white/50 ">
                    <Link 
                    activeClass="active"
                    smooth={true}
                    spy={true}
                    offset={-200}
                        to="home"
                        className="cursor-pointer flex items-center bg-white bg-opacity-20 p-4 hover:bg-opacity-40 rounded-full"
                    >
                        <BiHomeAlt />
                    </Link>
                    <Link 
                    activeClass="active"
                    smooth={true}
                    spy={true}
                        to="about"
                        className="cursor-pointer flex items-center bg-white bg-opacity-20 p-4 hover:bg-opacity-40 rounded-full"
                    >
                        <BiUser />
                    </Link>
                    <Link 
                    activeClass="active"
                    smooth={true}
                    spy={true}
                        to="service"
                        className="cursor-pointer flex items-center bg-white bg-opacity-20 p-4 hover:bg-opacity-40 rounded-full"
                    >
                        <BsClipboardData />
                    </Link>
                    <Link 
                    activeClass="active"
                    smooth={true}
                    spy={true}
                        to="work"
                        className="cursor-pointer flex items-center bg-white bg-opacity-20 p-4 hover:bg-opacity-40 rounded-full"
                    >
                        <BsBriefcase />
                    </Link>
                    <Link 
                    activeClass="active"
                    smooth={true}
                    spy={true}
                        to="contact"
                        className="cursor-pointer flex items-center bg-white bg-opacity-20 p-4 hover:bg-opacity-40 rounded-full"
                    >
                        <BsChatSquareText />
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Nav;

import React from "react";
// import logo from "../assets/logo.svg";
import logo from "../assets/title.png";
const Header = () => {
    return (
        <div className=" py-8">
            <div className="container mx-auto">
                <div className="flex justify-between items-center">
                    {/* logo */}
                    <a href="#">
                        <img src={logo} alt="" />
                    </a>
                    {/* button */}
                    <button className="btn btn-sm">
                        <a  href="https://drive.google.com/file/d/1QdYwDZMGuXZPZvaBPRrrTyI7ZI_gR8hP/view?usp=sharing" target="_blank">
                            View Resume
                        </a>{" "}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Header;

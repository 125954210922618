import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import img1 from "../assets/portfolio-img1.png";
import img2 from "../assets/portfolio-img2.png";
import img3 from "../assets/portfolio-img3.png";

import { HiExternalLink } from "react-icons/hi";

const Work = () => {
    return (
        <section className="section" id="work">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row gap-x-10">
                    <div className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0 ">
                        {/* text */}
                        <motion.div
                            variants={fadeIn("left", 0.3)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.3 }}
                            className="lg:mb-2"
                        >
                            <h2 className="h2 leading-tight text-accent">
                                My Leatest work
                            </h2>
                            <p className="max-w-sm mb-16">
                                Discover my latest work that demonstrates my
                                expertise in designing and developing innovative
                                solutions. Explore my portfolio to witness the
                                transformative power of my work.
                            </p>
                            <button className="btn btn-sm">
                                View all projects
                            </button>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("left", 0.3)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.3 }}
                            className="group relative overflow-hidden border-2 border-white/50 rounded-xl"
                        >
                            {/* ovrtlay */}
                            <div className=" group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 "></div>
                            {/* img */}
                            <img
                                className="group-hover:scale-125 transition-all duration-500"
                                src={img2}
                                alt=""
                            />
                            {/* link */}
                            <div className="absolute -bottom-full left-12 group-hover:top-5 transition-all duration-500 z-50">
                                <div className="flex gap-x-5 lg:gap-x-12">
                                    <div className="flex">
                                        <a
                                            href="https://khela-ghor-9431f.web.app/"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Live
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/khela-ghor-client"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Client
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/khela-ghor-server"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Server
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                </div>
                            </div>
                            {/* text */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-12 transition-all duration-500 z-50">
                                <span className="text-gradient">
                                    MERN STACK
                                </span>
                            </div>
                            {/* ttitle */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-4 transition-all duration-700 z-50">
                                <span className="text-white text-3xl">
                                    Khela Ghor
                                </span>
                            </div>
                        </motion.div>
                    </div>
                    <motion.div
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1 flex flex-col  gap-y-10"
                    >
                        <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                            {/* ovrtlay */}
                            <div className=" group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 "></div>
                            {/* img */}
                            <img
                                className="group-hover:scale-125 transition-all duration-500"
                                src={img1}
                                alt=""
                            />
                            {/* links */}
                            <div className="absolute -bottom-full left-12 group-hover:top-5 transition-all duration-500 z-50">
                                <div className="flex gap-x-5 lg:gap-x-12">
                                    <div className="flex">
                                        <a
                                            href="https://elite-sports-academy.web.app/"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Live
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/elite-sports-client"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Client
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/elite-sports-server"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Server
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                </div>
                            </div>
                            {/* text */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-12 transition-all duration-500 z-50">
                                <span className="text-gradient">
                                    MERN STACK
                                </span>
                            </div>
                            {/* ttitle */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-4 transition-all duration-700 z-50">
                                <span className="text-white text-3xl">
                                    Elite Sports Academy
                                </span>
                            </div>
                        </div>
                        {/* img  */}
                        <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                            {/* ovrlay */}
                            <div className=" group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 "></div>
                            {/* img */}
                            <img
                                className="group-hover:scale-125 transition-all duration-500"
                                src={img3}
                                alt=""
                            />
                            {/* links */}
                            <div className="absolute -bottom-full left-12 group-hover:top-5 transition-all duration-500 z-50">
                                <div className="flex gap-x-12">
                                    <div className="flex">
                                        <a
                                            href="https://chef-recipe-hunter-clien-e96df.web.app/"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Live
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/rannaghor-client"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Client
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                    <div className="flex">
                                        <a
                                            href="https://github.com/alaminrifat/rannaghor-server"
                                            target="_blank"
                                            className="text-gradient"
                                        >
                                            Server
                                        </a>
                                        <HiExternalLink></HiExternalLink>
                                    </div>
                                </div>
                            </div>
                            {/* text */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-12 transition-all duration-500 z-50">
                                <span className="text-gradient">
                                    MERN STACK
                                </span>
                            </div>
                            {/* ttitle */}
                            <div className="absolute -bottom-full left-8 group-hover:bottom-4 transition-all duration-700 z-50">
                                <span className="text-white text-3xl">
                                    Ranna Ghor
                                </span>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default Work;

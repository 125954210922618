import React from "react";

const Footer = () => {
    return (
        <div className="section block lg:hidden mb-24">
            <footer className="w-full">
                <div className="flex items-center justify-between px-4">
                    <div className="text-white">
                        <h4 className="text-lg font-bold">Al Amin Rifat</h4>
                        <p className="text-sm">alaminrifat.aiub@gmail.com</p>
                    </div>

                    <a
                        href="https://drive.google.com/uc?id=1QdYwDZMGuXZPZvaBPRrrTyI7ZI_gR8hP&export=download"
                        className="btn btn-sm text-white font-semibold py-3 px-4 rounded"
                    >
                        Resume
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Footer;

import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../assets/91574-astronaut-illustration.json";
import {
    FaGithub,
    FaDownload,
    FaLinkedinIn,
    FaResearchgate,
    FaDiscord,
    FaWhatsapp,
} from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

import { fadeIn } from "../variants";

const Banner = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div
            className="min-h-[85vh] lg:min-h-[78vh] flex items-center "
            id="home"
        >
            <div className="container mx-auto">
                <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
                    {/* text */}
                    <div className="flex-1 text-center font-secondary lg:text-left">
                        <motion.h1
                            variants={fadeIn("up", 0.3)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
                        >
                            AL AMIN Rifat
                        </motion.h1>
                        <motion.div
                            variants={fadeIn("up", 0.4)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]"
                        >
                            <span className="mr-4">I am a</span>
                            {/* add I am a up TODO: */}
                            <TypeAnimation
                                sequence={[
                                    "Developer",
                                    2000,

                                    "Researcher",
                                    2000,
                                ]}
                                speed={50}
                                className="text-accent"
                                wrapper="span"
                                repeat={Infinity}
                            ></TypeAnimation>
                        </motion.div>
                        <motion.p
                            variants={fadeIn("up", 0.5)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            className="mb-8 max-w-lg mx-auto lg:ms-0"
                        >
                            Unlocking the power of MERN stack, I create seamless
                            digital experiences that captivate users and drive
                            results. Let's collaborate and build something
                            extraordinary together!
                        </motion.p>
                        <motion.div
                            variants={fadeIn("up", 0.6)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
                        >
                            <button className="btn btn-lg">
                                <a
                                    href="https://drive.google.com/uc?id=1QdYwDZMGuXZPZvaBPRrrTyI7ZI_gR8hP&export=download"
                                    className="flex items-center gap-x-2"
                                >
                                    <FaDownload></FaDownload>
                                    Download Resume
                                </a>
                            </button>
                            <a
                                href="#contact"
                                className="text-gradient btn-link"
                            >
                                Contact
                            </a>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("up", 0.7)}
                            initial="hidden"
                            whileInView={"show"}
                            viewport={{ once: false, amount: 0.7 }}
                            className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0"
                        >
                            <a
                                href="https://github.com/alaminrifat"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaGithub className="text-3xl" />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/alaminrifat/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaLinkedinIn className="text-3xl" />
                            </a>
                            <a
                                href="https://www.researchgate.net/profile/Muhammad-Al-Amin-Rifat-2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaResearchgate className="text-3xl" />
                            </a>
                            <a
                                href="https://discordapp.com/users/alaminrifat"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaDiscord className="text-3xl" />
                            </a>
                            <a
                                href="https://wa.me/+8801797372047"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaWhatsapp className="text-3xl" />
                            </a>
                        </motion.div>
                    </div>
                    <motion.div
                        variants={fadeIn("down", 0.5)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] "
                    >
                        {/* <img src={Image} alt="" className="scale-105" /> */}
                        <Lottie
                            options={defaultOptions}
                            // height={400}
                            // width={400}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Banner;

import React from "react";
import { BsArrowUpRight } from "react-icons/bs";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
    {
        name: "MERN stack Developer",
        description:
            "Unlocking the power of the MERN stack, I develop robust web applications that leverage the capabilities of MongoDB, Express.js, React.js, and Node.js.",
        link: "https://github.com/alaminrifat",
    },
    {
        name: "Researcher",
        description:
            "As a  researcher in the fields of blockchain technology, Web 3.0, security, and web technology, I explore the latest advancements and conduct in-depth analyses.",
        link: "https://www.researchgate.net/profile/Muhammad-Al-Amin-Rifat-2/research",
    },
];

const Services = () => {
    return (
        <div className="section" id="service">
            <div className="container mx-auto">
                <motion.div
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }}
                    className="flex flex-col lg:flex-row"
                >
                    {/* text */}
                    <div className="flex-1 lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0">
                        <h2 className="h2 text-accent mb-6">What I Do</h2>
                        <h3 className="h3 max-w-[455px] mb-16">
                            Unlocking the power of MERN stack, I create seamless
                            digital experiences that captivate users and drive
                            results. Let's collaborate and build something
                            extraordinary together!
                        </h3>
                        <button className="btn btn-sm">See my Work</button>
                    </div>
                    {/* services */}
                    <motion.div
                        variants={fadeIn("left", 0.5)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1"
                    >
                        <div className="">
                            {services.map((service, index) => {
                                const { name, description, link } = service;
                                return (
                                    <div
                                        key={index}
                                        className="border-b border-white/20 h-[146px] mb-[38px] flex"
                                    >
                                        <div className="max-w-[476px]">
                                            <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-6">
                                                {name}
                                            </h4>
                                            <p className="font-secondary leading-tight">
                                                {description}
                                            </p>
                                        </div>
                                        <div className="flex flex-col flex-1 items-end">
                                            <a
                                                href={link}
                                                className="btn  w-9 h-9 mb-[42px] flex justify-center items-center"
                                            >
                                                <BsArrowUpRight />{" "}
                                            </a>
                                            <a
                                                href={link}
                                                className="text-gradient text-sm"
                                            >
                                                Visit
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default Services;

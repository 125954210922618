import React, { useRef } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current);
        emailjs
            .sendForm(
                "service_h3fh61s",
                "template_23j5o3z",
                form.current,
                "efxkCUQngUW4K4kPa"
            )
            .then(
                (result) => {
                    toast.success(result.text, {
                        autoClose: 2000,
                        closeOnClick: true,
                    });
                },
                (error) => {
                    toast.error(error.text, {
                        autoClose: 2000,
                        closeOnClick: true,
                    });
                }
            );
    };
    return (
        <section className="lg:section py-16 mb-10" id="contact">
            <ToastContainer />
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row ">
                    {/* text */}
                    <motion.div
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1 flex justify-start items-center"
                    >
                        <div>
                            <h4 className="text-xl uppercase text-accent font-medium mb-2 tracking-wide">
                                Get in touch
                            </h4>
                            <h2 className="text-[45px] lg:text[90px] leading-none mb-12 ">
                                Let's build <br /> together
                            </h2>
                        </div>
                    </motion.div>
                    {/* form */}
                    <motion.form
                        ref={form}
                        onSubmit={sendEmail}
                        variants={fadeIn("left", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.3 }}
                        className="flex-1 border rounded-2xl flex flex-col gap-y-6 pb-12 p-6 items-start"
                    >
                        <input
                            type="text"
                            name="from_name"
                            id="from_name"
                            className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
                            placeholder="Your name"
                        />
                        <input
                            type="email"
                            name="user_email"
                            id=""
                            className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
                            placeholder="Your email"
                        />
                        <textarea
                            name="message"
                            className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12 "
                            placeholder="Your message ( please add your email here too ) "
                        ></textarea>
                        <input
                            type="submit"
                            className="btn btn-lg cursor-pointer"
                            value="Send message"
                        ></input>
                    </motion.form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
